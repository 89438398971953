<template>
  <span v-if="features?.share">
    <v-dialog
        v-model="dialog"
        persistent
        transition="dialog-bottom-transition"
        max-width="550"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mr-4" color="primary" text x-large v-bind="attrs"
               v-on="on">
          <v-icon left>
            mdi-share-variant
          </v-icon>
          Teilen und unterschreiben lassen
        </v-btn>
      </template>

      <v-card v-if="!step2">
        <v-card-text class="pt-4">
          <h2 class="text-h5">Teilen und Unterschreiben lassen</h2>
        </v-card-text>
        <v-card-text> Die Teilen-Funktion ermöglicht es, sensible Daten mit anderen zu teilen. Dabei ist jedoch Vorsicht
          geboten, da jeder mit dem Link Zugriff auf diese Daten erhält. Es ist wichtig, sicherzustellen, dass die
          Empfänger vertrauenswürdig sind und angemessene Sicherheitsmaßnahmen treffen, um die Daten zu schützen. Eine
          unbedachte Nutzung dieser Funktion kann zu unerwünschten Offenlegungen führen und potenziell sensible
          Informationen preisgeben. Daher ist es ratsam, vor dem Teilen sensibler Daten die Risiken abzuwägen und
          geeignete Sicherheitsvorkehrungen zu treffen, um unerwünschte Zugriffe zu verhindern.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="dialog = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="primary"
              @click="forward()"
          >
            Teilen
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card v-if="step2">
        <v-card-text class="pt-4">
          <h2 class="text-h5">Teilen und Unterschreiben lassen</h2>
        </v-card-text>
        <v-card-text class="text-center" v-if="wait">
              <v-progress-circular
                  :size="200"
                  :width="7"
                  indeterminate
                  color="primary"
              >
                Link wird generiert
              </v-progress-circular>
        </v-card-text>

       <v-card-text class="my-4" v-else>


            <v-text-field outlined readonly v-model="input" type="text" append-icon="mdi-content-copy"
                          @click="copyContent(input)" @click:append="copyContent(input)"/>

            <v-alert v-if="getIsSupported && false" outlined color="error">
              Dein Browser unterstüzt die kopierfunktion nicht, bitte kopiere die URL
            </v-alert>

         <v-alert v-if="getClipboardText.value === generatedUrl" color="primary">
           Die URL wurde erfolgreich Kopiert!
         </v-alert>

       </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="back()"
          >
            zurück
          </v-btn>
          <v-btn
              color="primary"
              @click="finish()"
          >
            Fertig
          </v-btn>
        </v-card-actions>
      </v-card>


    </v-dialog>
  </span>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import features from "@/internaldata/features.ts";
import {mapFields} from "vuex-map-fields";
import {ref} from 'vue'
import {useClipboard, usePermission} from '@vueuse/core'
import moment from "moment";

const {text, isSupported, copy, copied} = useClipboard()

export default {
  data: () => ({
    dialog: false,
    step2: false,
    wait: false,

    generatedUrl: '',
    input: ref(''),
    permissionRead: usePermission('clipboard-read'),
    permissionWrite: usePermission('clipboard-write')
  }),

  computed: {
    ...mapFields([
      'customer',
      'additionalInformation',
      'system.selectedProducts',
      'system.selectedBox',
      'system.shareToken',
    ]),

    features() {
      return features
    },

    getIsSupported: () => isSupported,
    getClipboardText: () => text,
  },


  methods: {
    copyContent(event) {
      return copy(event)
    },

    forward() {
      this.step2 = true
      this.askForToken()
    },
    back() {
      this.step2 = false
    },
    async finish() {
      this.step2 = false
      await this.$router.push({path: '/vielendank'})
    },

    async askForToken() {
      if (!this.customer.moveToUsDate) {
        this.customer.moveToUsDate = moment(moment.now()).format("YYYY-MM-DD");
      }

      const output = {
        customer: this.customer,
        additionalInformation: this.additionalInformation,
        selectedProducts: this.selectedProducts,
        selectedBox: this.selectedBox
      }

      const url = 'https://www.docdorado.de/get/signing/token'

      if(!this.shareToken || this.shareToken === ''){
        this.wait = false
        fetch(url, {
          method: 'POST',
          body: JSON.stringify(output)
        }).then(response => {
          if (response.ok) {
            response.json().then(data => {
              this.shareToken = data.token
              const gUrl = `${window.location.href}/${this.shareToken}`;
              this.input = gUrl
              this.generatedUrl = gUrl
            })
          } else {
            response.text()
                .then(t => {
                  console.error('irgendwas ist schiefgelaufen ', t)
                })
          }
        }).catch(e => {
              console.log("Errrorr", e)
            }
        ).finally(() => this.wait = false)
      } else  {
        this.input =`${window.location.href}/${this.shareToken}`
      }
    },

  },


}
</script>

<style scoped>

</style>