export const countryCodes = [
    {name: 'Albanien', dial_code: '+355', code: 'AL'},
    {name: 'Andorra', dial_code: '+376', code: 'AD'},
    {name: 'Österreich', dial_code: '+43', code: 'AT'},
    {name: 'Weißrussland', dial_code: '+375', code: 'BY'},
    {name: 'Belgien', dial_code: '+32', code: 'BE'},
    {name: 'Bosnien und Herzegowina', dial_code: '+387', code: 'BA'},
    {name: 'Bulgarien', dial_code: '+359', code: 'BG'},
    {name: 'Kroatien', dial_code: '+385', code: 'HR'},
    {name: 'Zypern', dial_code: '+357', code: 'CY'},
    {name: 'Tschechien', dial_code: '+420', code: 'CZ'},
    {name: 'Dänemark', dial_code: '+45', code: 'DK'},
    {name: 'Estland', dial_code: '+372', code: 'EE'},
    {name: 'Färöer', dial_code: '+298', code: 'FO'},
    {name: 'Finnland', dial_code: '+358', code: 'FI'},
    {name: 'Frankreich', dial_code: '+33', code: 'FR'},
    {name: 'Deutschland', dial_code: '+49', code: 'DE'},
    {name: 'Gibraltar', dial_code: '+350', code: 'GI'},
    {name: 'Griechenland', dial_code: '+30', code: 'GR'},
    {name: 'Grönland', dial_code: '+299', code: 'GL'},
    {name: 'Ungarn', dial_code: '+36', code: 'HU'},
    {name: 'Island', dial_code: '+354', code: 'IS'},
    {name: 'Irland', dial_code: '+353', code: 'IE'},
    {name: 'Italien', dial_code: '+39', code: 'IT'},
    {name: 'Kosovo', dial_code: '+383', code: 'XK'},
    {name: 'Lettland', dial_code: '+371', code: 'LV'},
    {name: 'Liechtenstein', dial_code: '+423', code: 'LI'},
    {name: 'Litauen', dial_code: '+370', code: 'LT'},
    {name: 'Luxemburg', dial_code: '+352', code: 'LU'},
    {name: 'Malta', dial_code: '+356', code: 'MT'},
    {name: 'Moldawien', dial_code: '+373', code: 'MD'},
    {name: 'Monaco', dial_code: '+377', code: 'MC'},
    {name: 'Montenegro', dial_code: '+382', code: 'ME'},
    {name: 'Niederlande', dial_code: '+31', code: 'NL'},
    {name: 'Nordmazedonien', dial_code: '+389', code: 'MK'},
    {name: 'Norwegen', dial_code: '+47', code: 'NO'},
    {name: 'Polen', dial_code: '+48', code: 'PL'},
    {name: 'Portugal', dial_code: '+351', code: 'PT'},
    {name: 'Rumänien', dial_code: '+40', code: 'RO'},
    {name: 'Russland', dial_code: '+7', code: 'RU'},
    {name: 'San Marino', dial_code: '+378', code: 'SM'},
    {name: 'Serbien', dial_code: '+381', code: 'RS'},
    {name: 'Slowakei', dial_code: '+421', code: 'SK'},
    {name: 'Slowenien', dial_code: '+386', code: 'SI'},
    {name: 'Spanien', dial_code: '+34', code: 'ES'},
    {name: 'Schweden', dial_code: '+46', code: 'SE'},
    {name: 'Schweiz', dial_code: '+41', code: 'CH'},
    {name: 'Ukraine', dial_code: '+380', code: 'UA'},
    {name: 'Vereinigtes Königreich', dial_code: '+44', code: 'GB'},
    {name: 'Vatikanstadt', dial_code: '+379', code: 'VA'},
]