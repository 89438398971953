import Vue from "vue";
import VueRouter from "vue-router";
import Step1Component from "../views/Step1Component.vue";
import Step2Component from "../views/Step2Component.vue";
import Step3Component from "../views/Step3Component.vue";
import Step4Component from "../views/Step4Component.vue";
import Offline from "../views/Offline.vue";
import Router from "vue-router";

Vue.use(VueRouter);

const routes = [
    {path: '/', redirect: {path: '/boxauswahl'}},
    {path: '/boxauswahl', component: Step1Component},
    {path: '/dateneingabe', component: Step2Component},
    {path: '/unterschrift', component: Step3Component},
    {path: '/unterschrift/:token', component: () => import("../views/Step3BComponent.vue")},
    {path: '/vielendank', component: Step4Component},
    {path: '/offline', component: Offline}
]

const router = new Router({
    routes
})

export default router