<template>

  <!-- lg and up -->
  <v-footer color="white">
    <v-row justify="center" class="hidden-lg-and-down">
      <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="3">
        <v-card flat>
          <v-card-title class="display-1 primary--text">Vorteile</v-card-title>
          <v-card-text v-for="a in advantages" :key="a">
            <v-icon color="primary" class="mr-2">mdi-thumb-up</v-icon>
            <span class="text-start text-body-1">{{ a }}</span>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="3">
        <v-card flat>
          <v-card-title class="display-1 primary--text">Leistungen</v-card-title>
          <v-card-text v-for="s in services" :key="s.text">
            <a :href="s.link" style="text-decoration: none">
              <span class="text-start text-body-1">{{ s.text }}</span>
            </a>
          </v-card-text>
        </v-card>
      </v-col>


      <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="3">
        <v-card flat>
          <v-card-title class="display-1 primary--text">Info</v-card-title>
          <v-card-text v-for="i in infos" :key="i.text">
            <a :href="i.link" style="text-decoration: none">
              <span class="text-start text-body-1">{{ i.text }}</span>
            </a>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="3">
        <v-card flat>
          <v-card-title class="display-1 primary--text">Rechtliches</v-card-title>
          <v-card-text v-for="l in legal" :key="l.text">
            <a :href="l.link" style="text-decoration: none">
              <span class="text-start text-body-1">{{ l.text }}</span>
            </a>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- lg and md -->

    <v-row justify="center" class="hidden-xl-only hidden-sm-only hidden-xs-only">
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
        <v-card flat>
          <v-card-title class="display-1 primary--text">Vorteile</v-card-title>
          <v-card-text v-for="a in advantages" :key="a">
            <v-icon color="primary" class="mr-2">mdi-thumb-up</v-icon>
            <span class="text-start text-body-1">{{ a }}</span>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3" class="text-right">
        <v-card flat class="text-right">
          <v-card-text class="display-1 primary--text text-right">Leistungen</v-card-text>
          <v-card-text v-for="s in services" :key="s.text" class="text-right">
            <a :href="s.link" style="text-decoration: none">
              <span class="text-start text-body-1">{{ s.text }}</span>
            </a>
          </v-card-text>

          <v-card-text v-if="requestChain.length !== 0">
            <v-badge :content="requestChain.length">
              <a href="#/offline" style="text-decoration: none">
                <span class="text-start text-body-1">Offline Anträge</span>
              </a>
            </v-badge>
          </v-card-text>
        </v-card>
      </v-col>


      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
        <v-card flat>
          <v-card-title class="display-1 primary--text">Info</v-card-title>
          <v-card-text v-for="i in infos" :key="i.text">
            <a :href="i.link" style="text-decoration: none">
              <span class="text-start text-body-1">{{ i.text }}</span>
            </a>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3" class="text-right">
        <v-card flat class="text-right">
          <v-card-text class="display-1 primary--text text-right">Rechtliches</v-card-text>
          <v-card-text v-for="l in legal" :key="l.text">
            <a :href="l.link" style="text-decoration: none">
              <span class="text-start text-body-1">{{ l.text }}</span>
            </a>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


    <!-- xs and sm -->

    <v-row justify="center" class="hidden-xl-only hidden-md-only hidden-lg-only">
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
        <v-card flat>
          <v-card-title class="display-1 primary--text">Vorteile</v-card-title>
          <v-card-text v-for="a in advantages" :key="a">
            <v-icon color="primary" class="mr-2">mdi-thumb-up</v-icon>
            <span class="text-start text-body-1">{{ a }}</span>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
        <v-card flat>
          <v-card-title class="display-1 primary--text">Leistungen</v-card-title>
          <v-card-text v-for="s in services" :key="s.text">
            <a :href="s.link" style="text-decoration: none">
              <span class="text-start text-body-1">{{ s.text }}</span>
            </a>
          </v-card-text>


          <v-card-text v-if="requestChain.length !== 0">
            <v-badge :content="requestChain.length">
              <a href="#/offline" style="text-decoration: none">
                <span class="text-start text-body-1">Offline Anträge</span>
              </a>
            </v-badge>
          </v-card-text>
        </v-card>
      </v-col>


      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
        <v-card flat>
          <v-card-title class="display-1 primary--text">Info</v-card-title>
          <v-card-text v-for="i in infos" :key="i.text">
            <a :href="i.link" style="text-decoration: none">
              <span class="text-start text-body-1">{{ i.text }}</span>
            </a>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3" >
        <v-card flat >
          <v-card-title class="display-1 primary--text">Rechtliches</v-card-title>
          <v-card-text v-for="l in legal" :key="l.text">
            <a :href="l.link" style="text-decoration: none">
              <span class="text-start text-body-1">{{ l.text }}</span>
            </a>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-footer>
</template>



<script>
import footerInfo from "@/indivconfig/footerInfo.json";
import {mapFields} from "vuex-map-fields";
import {useOnline} from "@vueuse/core";

export default {
  name: 'PBFooter',
  methods: {
    goToLink(link) {
      window.location.href = link;
    },
  },
  data() {
    return {
      advantages: footerInfo.vorteile,
      infos: footerInfo.infos,
      services: footerInfo.leistungen,
      legal: footerInfo.rechtliches,
    };
  },

  computed: {
    ...mapFields([
      'system.requestChain'
    ]),
    isOnline(){
      return useOnline()?.value
    }
  }
};
</script>