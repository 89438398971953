
import {defineComponent} from "vue";
import PBHeader from "@/components/PB-Header.vue";
import PBFooter from "@/components/PBFooter.vue";
import releaseJson from "@/internaldata/release.json"
import {mapFields} from "vuex-map-fields";

export default defineComponent({
  components: {PBFooter, PBHeader},

  data: () => ({

  }),

  mounted() {
    const prefill = this.$route.query.prefill;

    if (prefill) {
      const jsonString = atob(prefill.toString());
      const storeOverride = JSON.parse(jsonString)
      this.$store.commit("overrideStore", storeOverride)
    } else console.warn("prefill failed object is empty")
  },

  methods: {
    fillLocalStoreWithTestData() {
    }
  },

  computed: {
    ...mapFields([""]),

    release() {
      return releaseJson
    },

    isLocal() {
      return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
    }
  }
})

